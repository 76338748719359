
import { defineComponent } from "vue";

export default defineComponent({
  name: "InputField",
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  methods: {
    resetForm() {
      (this.$refs["inputFieldValue"] as any).value = this.field.defaultValue;
    },
  },
});
