
import { defineComponent, reactive, toRefs, ref } from "vue";
import Footer from "./Footer.vue";
import Header from "./Header.vue";
import FormGenerator from "../components/forms/FormGenerator.vue";
import ApiResources from "../resources";
import {
  emailValidator,
  requiredValidator,
  phoneValidator,
} from "../validators";

export default defineComponent({
  name: "Home",
  components: {
    Footer,
    FormGenerator,
    Header,
  },
  setup: () => {
    const validatedForm: any = ref({ requestFrom: "dpa" });
    const state = reactive({
      schema: [
        {
          fieldType: "InputField",
          placeholder: "Name*",
          label: "",
          name: "name",
          type: "text",
          value: "",
          class: "form-group col-md-6",
          defaultValue: "",
          validators: [
            { validator: requiredValidator, message: "Name is required" },
          ],
        },
        {
          fieldType: "InputField",
          placeholder: "Email*",
          label: "",
          name: "email",
          type: "email",
          value: "",
          class: "form-group col-md-6",
          defaultValue: "",
          validators: [
            { validator: requiredValidator, message: "Email is required" },
            { validator: emailValidator, message: "Please enter valid email" },
          ],
        },
        {
          fieldType: "InputField",
          placeholder: "Phone*",
          label: "",
          name: "phone",
          type: "text",
          value: "",
          class: "form-group col-md-6",
          defaultValue: "",
          validators: [
            { validator: requiredValidator, message: "Phone is required" },
            { validator: phoneValidator, message: "Please enter valid phone" },
          ],
        },
        {
          fieldType: "InputField",
          placeholder: "How did you discover Diverse Production Services?*",
          label: "",
          name: "discover",
          type: "text",
          value: "",
          class: "form-group col-md-6",
          defaultValue: "",
          validators: [
            {
              validator: requiredValidator,
              message: "Discover Diverse Production Services? is required",
            },
          ],
        },
        {
          fieldType: "TextAreaField",
          placeholder: "Message",
          label: "",
          name: "comments",
          type: "text",
          value: "",
          class: "form-group col-md-12",
          defaultValue: "",
          rows: 5,
          validators: [],
        },
      ],
      onSuccess: false,
      submitButton: {
        class: "btn bt-submit mt-5 mb-5",
        value: "Submit",
        loading: "Submitting...",
      },
    });

    return { ...toRefs(state), validatedForm };
  },
  methods: {
    async validForm(data: any) {
      const proxy1 = new Proxy(data, {});
      const dataValues = JSON.parse(JSON.stringify(proxy1));
      dataValues.forEach((element: any) => {
        this.validatedForm[element.name] = element.value;
      });
      try {
        let approveEndpoint = ApiResources.SupportResource.wrap("/");
        await approveEndpoint.post(this.validatedForm);
        this.onSuccess = true;
        this.callRestForm();
        setTimeout(() => (this.onSuccess = false), 5000);
      } catch (err) {
        console.log("err", err);
      }
    },
    callRestForm() {
      (this.$refs["supportForm"] as any).resetForm();
    },
    scrollByClass(refName: any) {
      let el: any = this.$refs[refName];
      window.scrollTo({
        top: el.offsetTop,
        behavior: "smooth",
      });
    },
  },
});
