
import { defineComponent, ref, watch } from "vue";
import InputField from "./InputField.vue";
import TextAreaField from "./Textarea.vue";

export default defineComponent({
  name: "FormGenerator",
  components: { InputField, TextAreaField },
  props: {
    schema: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    submitButton: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  setup(props) {
    var submitted = false;
    var formValid = false;
    var formData = props.schema || [];
    var errorMessages: any = ref({});
    watch(errorMessages.value, () => {
      //   console.log("");
    });
    watch(formData, () => {
      //   console.log("");
    });
    return { formData, errorMessages, submitted, formValid };
  },
  methods: {
    updateForm(fieldName: any, value: any) {
      var foundIndex = this.formData.findIndex((x: any) => x.name == fieldName);
      this.formData[foundIndex].value = value;
      this.validate(fieldName, value, foundIndex);
    },
    validate(fieldName: any, value: any, index: any) {
      let errorsList: any = [];
      this.formData[index].validators.forEach((element: any) => {
        element.value = value;
        let error = element.validator(element);
        if (error == true) {
          errorsList.push(element.message);
        }
        this.errorMessages[`${fieldName}`] = errorsList[0];
      });
    },
    onSubmitted() {
      this.submitted = true;
      this.formData.forEach((element: any, index: any) => {
        this.validate(element.name, element.value, index);
      });
      const proxy1 = new Proxy(this.errorMessages, {});
      const errorValues = JSON.parse(JSON.stringify(proxy1));
      if (Object.keys(errorValues).length === 0) {
        this.$emit("updatedForm", this.formData);
      } else {
        this.submitted = false;
        return;
      }
    },
    resetForm() {
      this.errorMessages = {};
      this.formData.forEach((field: any) => {
        (this.$refs[`${field.name}`] as any).resetForm();
        field.value = field.defaultValue;
      });
      this.submitted = false;
    },
  },
});
