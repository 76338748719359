<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <router-view />
</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&display=swap");
body {
  padding: 0;
  margin: 0;
}
h1,
h2,
h3 {
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
}
#app {
  margin: 0;
  padding: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;
}

.header {
  background: url(assets/images/banner.jpg) no-repeat 0 0;
  background-size: cover;
  height: 85vh;
  
  h1 {
    margin: 20% 0 60px 0;
    color: #fff;
    font-size: 70px;
    position: absolute;
    bottom: 0;
    line-height: 1;
    span {
      color: #d9af1c;
      // font-size: 50px;
    }
  }
}
.heading {
  margin-left: 45px;
  margin-bottom: 20px;
}
.content {
  padding: 100px 0;
  h2 {
    font-family: "Oswald", sans-serif;
    margin-bottom: 30px;
  }
}
.demo_form {
  padding-bottom: 50px;
  background: #ebebeb;
  h2 {
    background: #d9af1c;
    margin: 0 0 60px 0;
    padding: 25px 0;
  }
  input,
  textArea {
    margin: 20px 0 0 0;
    padding: 10px;
    border: 1px solid #d9d9d9;
  }
  .bt-submit {
    background: #272727;
    color: #fff;
    padding: 8px 50px;
  }
  .bt-submit:hover {
    color: #fff;
  }
  .bt-submit:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}
</style>
