import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "text-danger float-start" }
const _hoisted_4 = { class: "form-group col-md-12 text-center" }
const _hoisted_5 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formData, (field, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(field.class)
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(field.fieldType), _mergeProps({
            onInput: ($event: any) => (_ctx.updateForm(field.name, $event.target.value.trim()))
          }, { field: field }, {
            emailValid: field.invalid,
            ref: field.name
          }), null, 16, ["onInput", "emailValid"])),
          _createElementVNode("small", _hoisted_3, _toDisplayString(_ctx.errorMessages[field.name]), 1)
        ], 2))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("input", {
        class: _normalizeClass(_ctx.submitButton.class),
        type: "submit",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSubmitted && _ctx.onSubmitted(...args))),
        value: _ctx.submitted ? _ctx.submitButton.loading : _ctx.submitButton.value
      }, null, 10, _hoisted_5)
    ])
  ]))
}