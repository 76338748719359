import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "float-start" }
const _hoisted_2 = ["type", "name", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.field.label), 1),
    _createElementVNode("input", {
      class: "col-md-12",
      type: _ctx.field.type,
      name: _ctx.field.name,
      placeholder: _ctx.field.placeholder,
      autocomplete: "off",
      ref: "inputFieldValue"
    }, null, 8, _hoisted_2)
  ]))
}