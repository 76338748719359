
import { defineComponent, reactive, toRefs } from "vue";
export default defineComponent({
  name: "Header",
  setup: () => {
    const state = reactive({
      menuItems: [
        { name: "ABOUT", className: "about-section" },
        { name: "CONTACT", className: "contact-section" },
      ],
      selectedMenuItem: "",
      openMenu: false,
    });
    return { ...toRefs(state) };
  },
  methods: {
    scrollDownToElement(className: string) {
      this.openMenu = false;
      this.selectedMenuItem = className;
      this.$emit("scrollToElement", className);
    },
  },
});
