export function requiredValidator(control: any): boolean | null {
  if (!control.value && !control.value.length) {
    return true;
  }
  return null;
}

export function minValidator(control: any): boolean | null {
  if (control.value && control.value.length < control.min) {
    return true;
  }
  return null;
}

export function maxValidator(control: any): boolean | null {
  if (control.value && control.value.length > control.max) {
    return true;
  }
  return null;
}

export function emailValidator(control: any): boolean | null {
  const re = /(.+)@(.+){2,}\.(.+){2,}/;
  return !re.test(control.value.toLowerCase());
}

export function passwordValidator(control: any): boolean | null {
  const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
  return !re.test(control.value);
}

export function phoneValidator(control: any): boolean | null {
  const re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  return !re.test(control.value);
}
