import Resource from "rest-resource";

import resourceConfig from "./constants";

export const client = Resource.client.constructor("https://formapi.revolutiones.com");

export class BaseResource extends Resource {
  static client = client;

  static search(query: any) {
    return this.list({ query: { q: query } });
  }
}

const getResources = () => {
  const resourcesList: any = [];

  resourceConfig.forEach((element) => {
    const relatedResources: any = {};

    if (element.relatedResource) {
      for (const key in element.relatedResource) {
        relatedResources[key] = resourcesList[element.relatedResource[key]];
      }
    }

    resourcesList[element.resourceName] = class extends BaseResource {
      static endpoint = element.endPoint;

      static validation = element.validations || {};

      static related = relatedResources;

      toString() {
        return this.attributes.name;
      }
    };
  });

  return resourcesList;
};

export default getResources();
